// import axios from 'axios';
import axiosApi from '@/api/index';

let urlPath = '/api/smsSend';

async function friend(mobileNo, buildingId) {
	let params = {
		mobileNo: mobileNo,
		buildingId: buildingId,
	};
	return axiosApi.post(`${urlPath}/friend`, params);
}
async function friendRegister(mobileNo) {
	let params = {
		mobileNo: mobileNo,
	};
	return axiosApi.post(`${urlPath}/friendRegister`, params);
}

export default { friend, friendRegister };
