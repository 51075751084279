<template>
	<!-- container -->
	<div>
		<!-- titArea -->
		<div class="titArea v3">
			<div class="inner">
				<h2>{{ data.name }}</h2>
				<div class="rightArea">
					<a class="btn btnWhite ss" @click="onProject()">프로젝트</a>
					<a class="btn btnWhite ss" @click="onTenant()">입주사</a>
				</div>
			</div>
		</div>
		<!--// titArea -->

		<!-- fullArea -->
		<div class="fullArea">
			<!-- buildWrap -->
			<div class="buildWrap">
				<!-- buildArea -->
				<div class="buildArea">
					<a v-if="auth" href="javascript:void(0)" @click="onEdit" class="btnSet">셋팅</a>
					<!-- build -->
					<div v-if="imageUrl" class="imgBox">
						<img style="width: 100%; height: auto;" :src="imageUrl" />
					</div>
					<div v-else>
						<div class="build">
							<ul>
								<li v-for="(item, idx) in data.basements" :key="idx">{{ item.name }}</li>
							</ul>
							<ul>
								<li v-for="(item, idx) in data.grounds" :key="idx">{{ item.name }}</li>
							</ul>
						</div>
						<!--// build -->
						<!-- legend -->
						<div class="legend">
							<span class="v1">지상</span>
							<span class="v2">지하</span>
						</div>
						<!--// legend -->
					</div>
				</div>
				<!--// buildArea -->
				<!-- buildListArea -->
				<div class="buildListArea">
					<!-- tabWrap -->
					<div class="tabWrap">
						<ul>
							<li :class="tab[0]"><a @click="onTab(0)">층목록</a></li>
							<li :class="tab[1]"><a @click="onTab(1)">스케줄</a></li>
							<li :class="tab[2]"><a @click="onTab(2)">글목록</a></li>
						</ul>
						<div class="tabBox">
							<!-- 층목록 -->
							<div id="tab01" v-if="tab[0]">
								<ul class="buildingList">
									<li v-for="(item, idx) in data.basements" :key="'base' + idx">
										<router-link :to="{ name: 'floorDetail', params: { id: item.id } }">
											<div class="imgArea">
												<img :src="item.imageId ? '/file/' + item.imageId : '/resources/images/no-img02.svg'" alt="" />
											</div>
											<strong>{{ item.name }}</strong>
											<p>
												<strong v-for="(item, idx2) in getOwnerships(item.rooms)" :key="idx2">
													<span>{{ item }}</span>
												</strong>
											</p>
										</router-link>
									</li>
									<li v-for="(item, idx) in data.grounds" :key="'ground' + idx">
										<router-link :to="{ name: 'floorDetail', params: { id: item.id } }">
											<div class="imgArea">
												<img :src="item.imageId ? '/file/' + item.imageId : '/resources/images/no-img02.svg'" alt="" />
											</div>
											<strong>{{ item.name }}</strong>
											<p>
												<strong v-for="(item, idx2) in getOwnerships(item.rooms)" :key="idx2">
													<span>{{ item }}</span>
												</strong>
											</p>
										</router-link>
									</li>
								</ul>
							</div>
							<!--// 층목록 -->
							<!-- 스케줄 -->
							<div id="tab02" v-else-if="tab[1]">
								<!-- weekList -->
								<div class="weekList">
									<!-- tit -->
									<div class="tit">
										<strong>{{ titleDate }}</strong>
										<a @click="prevWeek()" class="btnPrev">이전</a>
										<a @click="nextWeek()" class="btnNext">다음</a>
									</div>
									<!--// tit -->
									<!-- 선택됐을 경우 curr -->
									<!-- 스케줄이 있을 경우 have -->
									<ul>
										<li
											v-for="(week, index) in weeks"
											:key="index"
											@click="selectDate(index, week.date)"
											:class="
												(week.schedule && week.schedule.length > 0) || (week.project && week.project.length > 0)
													? 'have ' + weekClass[index]
													: weekClass[index]
											"
										>
											<a
												><p>
													<span>{{ getDayOfWeekString(index) }}</span
													>{{ $moment(week.date).format('D') }}
												</p></a
											>
										</li>
									</ul>
								</div>
								<!--// weekList -->
								<!-- communityList -->
								<div class="communityList v2">
									<ul
										v-if="(scheduleMemoList && scheduleMemoList.length > 0) || (projectList && projectList.length > 0)"
									>
										<li v-for="(scheduleMemo, index) in scheduleMemoList" :key="'a' + index">
											<a @click="onMemo(scheduleMemo.id, 'schedule')">
												<strong><span>[스케줄]</span> {{ scheduleMemo.schedulename }}</strong>
												<div class="viewInfo">
													<div class="user">
														<div class="imgArea">
															<img
																:src="`/file/${scheduleMemo.creatorImageId}`"
																alt=""
																v-if="scheduleMemo.creatorImageId"
															/>
														</div>
														<p>{{ scheduleMemo.creatorName }}</p>
													</div>
													<p class="date">
														<span>{{ scheduleMemo.createDate | dateFormatYear }}</span>
														<span>{{ scheduleMemo.createDate | dateFormatMonth }}</span>
													</p>
												</div>
											</a>
										</li>
										<li v-for="(project, index) in projectList" :key="'b' + index">
											<a @click="onProjectMemoList(project.id)">
												<strong><span>[프로젝트]</span> {{ project.name }}</strong>
												<strong style="font-size: 13px; color: #999;"
													>[{{ project.beginDate }} ~ {{ project.endDate }}]</strong
												>
												<div class="viewInfo">
													<div class="user">
														<div class="imgArea">
															<img :src="`/file/${project.creatorImageId}`" alt="" v-if="project.creatorImageId" />
														</div>
														<p>{{ project.creatorName }}</p>
													</div>
													<p class="date">
														<span>{{ project.createDate | dateFormatYear }}</span>
														<span>{{ project.createDate | dateFormatMonth }}</span>
													</p>
												</div>
											</a>
										</li>
									</ul>
									<ul v-else>
										<!-- noData -->
										<div class="noData">
											<p>등록된 일정이 없습니다.</p>
										</div>
									</ul>
								</div>
								<!--// communityList -->
							</div>
							<!--// 스케줄 -->
							<!-- 글목록 -->
							<div id="tab03" v-else>
								<div class="memoList">
									<!-- memoList -->
									<ul v-if="total > 0">
										<li v-for="(memo, index) in memoList" :key="index">
											<a @click="onMemo(memo.id, 'memoList')">
												<!-- location -->
												<div class="location">
													<span>{{ memo.buildingName }}</span>
													<span v-if="memo.floorId != null">{{ memo.floorName }}</span>
													<span v-if="memo.roomId != null">{{ memo.roomName }}</span>
													<span v-if="memo.objectPinId != null">{{ memo.objectName }}</span>
													<span v-if="memo.locations && memo.locations.length > 1" class="locationCount"
														>외 {{ memo.locations.length - 1 }}개</span
													>
												</div>
												<!--// location -->
												<!-- memo -->
												<div class="memo">
													<div class="user">
														<div class="imgArea">
															<p><img :src="`/file/${memo.creatorImageId}`" alt="" v-if="memo.creatorImageId" /></p>
														</div>
														<p>{{ memo.creatorName }}</p>
													</div>
													<div class="user" v-if="memo.projectName != null">
														<span> <strong>[프로젝트]</strong> </span>
														<span class="title">&nbsp;{{ memo.projectName }}</span>
														<span class="interval">
															&nbsp;({{ memo.projectBeginDate | dateFormatYear }}~{{
																memo.projectEndDate | dateFormatYear
															}})
														</span>
													</div>
													<div class="user" v-if="memo.schedule.name">
														<span>
															<strong>[스케줄]</strong>
														</span>
														<span class="title">&nbsp;{{ memo.schedule.name }}</span>
														<span class="interval"
															>&nbsp;({{ setIntervalStr(memo.schedule.intervalType) }}
															{{ setDateFormat(memo.schedule) }})</span
														>
													</div>
													<p class="date">
														<span>{{ memo.createDate | dateFormatYear }}</span>
														<span>{{ memo.createDate | dateFormatMonth }}</span>
													</p>
													<p>
														<span>
															<pre v-if="memo.content" style="font-family: 'Noto Sans KR', sans-serif;">{{
																enterContent(memo.content).replace(/ /g, '&nbsp;')
															}}</pre>
														</span>
													</p>
												</div>
												<!--// memo -->
											</a>
											<!-- imgList -->
											<div class="devImgList">
												<div class="inBox">
													<p v-for="(memoFile, index) in memo.memoFiles" :key="index">
														<img
															:src="`/file/${memoFile.fileId}`"
															alt=""
															@click="onOpenImage(memoFile.fileId, memo.memoFiles)"
														/>
													</p>
												</div>
											</div>
											<!--// imgList -->
											<!-- viewInfo -->
											<div class="viewInfo">
												<a class="btnComment">{{ memo.commentCount }}</a>
												<a class="btnViews">{{ memo.views }}</a>
											</div>
											<!--// viewInfo -->
										</li>
									</ul>
									<ul v-else>
										<h3>최신글</h3>
										<!-- noData -->
										<div class="noData">
											<p>등록된 최신글이 없습니다.</p>
										</div>
									</ul>
								</div>
								<!--// memoList -->
							</div>
							<!--// 글목록 -->
						</div>
						<div class="btnFriArea">
							<div class="inBox">
								<a class="btnFriAdd" name="layerAddFriendReq" @click="openFriendReq()" v-if="auth == true">친구 추가</a>
								<a class="btnEdit" v-if="friendAuth == true" @click="onMemoRegist()">글쓰기</a>
							</div>
						</div>
					</div>
					<!--// tabWrap -->
				</div>
				<!--// buildListArea -->
			</div>
			<!--// buildWrap -->
		</div>
		<!--// fullArea -->
		<!-- 팝업 -->
		<div :class="addFriendReqClass" id="layerAddFriendReq">
			<div class="inner" style="width:400px">
				<strong class="tit">공유회원 추가</strong>
				<div class="cont">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>권한설정</dt>
							<dd>
								<select title="권한설정 선택" v-model="authority">
									<option value="A">메모 및 댓글 쓰기 권한</option>
									<option value="B">댓글 쓰기 권한</option>
								</select>
							</dd>
						</dl>
						<dl>
							<dt>
								공유회원 추가
							</dt>
							<dd>
								<input
									type="text"
									title="공유회원 휴대전화 입력"
									placeholder="휴대전화 또는 이메일아이디 입력해주세요."
									required="required"
									maxlength="100"
									v-model="friendValue"
								/>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button
							type="button"
							class="btn"
							name="layerAddFriendReq"
							@click="onAddFriendReq()"
							:disabled="!addFriendReqBtn"
						>
							보내기
						</button>
					</div>
					<!--// btnArea -->
				</div>
				<a href="javascript:void(0)" name="layerAddFriendReq" @click="closeFriendReq">닫기</a>
			</div>
		</div>
		<!-- 팝업 -->
		<PopUpImage ref="popUpImage"></PopUpImage>
	</div>
	<!--// container -->
</template>
<script>
import { numberComma, dateFormatYear, dateFormatMonth, getAuth, setIntervalStr } from '@/utils/cmm.js';
import { showSpinner, hideSpinner } from '@/utils/cmm';
import building from '@/api/building';
import floor from '@/api/floor';
import friend from '@/api/friend';
import smsSend from '@/api/smsSend';
import floorplan from '@/api/floorplan';
import memo from '@/api/memo';
import schedule from '@/api/schedule';
import project from '@/api/project';
import PopUpImage from '../popup/popUpImage';
export default {
	components: { PopUpImage },
	filters: {
		numberComma,
		dateFormatYear,
		dateFormatMonth,
	},
	data() {
		return {
			buildingId: null,
			data: { basements: [], grounds: [] },
			authority: 'A',
			mobileNo: null,
			loginId: null,
			friendKey: null,
			friendValue: null,
			addFriendReqClass: 'friendLayer layerPop',
			imageUrl: null,
			auth: false,
			floorPlanCodes: ['FRPLBASC', 'FRPLELEC', 'FRPLFIRE', 'FRPLHYPL'],
			tab: ['curr'],
			total: 0,
			offset: 0,
			limit: 10,
			memoList: [],
			scheduleList: [],
			targetDate: this.$route.params.targetDate ? this.$route.params.targetDate : this.$moment(),
			weeks: [],
			weekClass: [],
			titleDate: null,
			scheduleMemoList: [],
			projectList: [],
			friendAuth: false,
		};
	},
	computed: {
		addFriendReqBtn() {
			return this.friendValue ? true : false;
		},
	},
	mounted() {
		this.buildingId = Number(this.$route.params.id);
		if (isNaN(this.buildingId)) {
			this.$alert('잘못된 접근입니다.');
			this.$router.push({ name: 'buildingList' });
			return;
		}
		this.$EventBus.$on('paging', () => {
			if (this.total <= this.offset) return;
			this.onGetMemo();
		});
		this.reload();
		if (this.$route.params.flag === 'schedule') {
			this.onTab(1);
		} else if (this.$route.params.flag == 'memoList') {
			this.onTab(2);
		}
	},
	beforeDestroy() {
		this.$EventBus.$off('paging');
	},
	methods: {
		async onTab(value) {
			if ('curr' === this.tab[value]) return;
			this.tab = [];
			this.tab[value] = 'curr';
			let initDate = this.$moment(this.targetDate);
			await this.emptyMemo();
			switch (value) {
				case 0:
					await this.reload();
					break;
				case 1:
					await this.getWeek(initDate);
					await this.selectDate(initDate.weekday(), initDate.format('YYYY-MM-DD'));
					break;
				case 2:
					await this.onGetMemo();
					break;
			}
		},
		onEdit() {
			this.$router.push({ name: 'buildingEdit', params: { id: this.buildingId } });
		},
		async reload() {
			try {
				showSpinner();
				let res = await building.getOne(this.buildingId);
				let floors = await floor.get({ buildingId: this.buildingId, withRoom: true });
				floors.data.forEach(it => {
					it.name = 0 > it.floors ? `B${-it.floors}층` : `${it.floors}층`;
					it.rooms && it.rooms.sort((l, r) => (l.ownership > r.ownership ? 1 : l.ownership < r.ownership ? -1 : 0));
				});

				for (let i = 0; i < floors.data.length; ++i) {
					const floor = floors.data[i];
					const plans = await floorplan.get({ floorId: floor.id });
					if (plans.data) {
						for (let j = 0; !floor.imageId && j < this.floorPlanCodes.length; ++j) {
							const plan = plans.data.find(it => it.type === this.floorPlanCodes[j]);
							floor.imageId = plan.imageId;
						}
					}
				}

				let grounds = floors.data.filter(it => 0 > it.floors);
				grounds = grounds && grounds.sort((l, r) => r.floors - l.floors);
				let basements = floors.data.filter(it => 0 < it.floors);
				basements.map(obj => (obj.floors == 999 ? (obj.name = '옥상') : obj.name));
				basements = basements && basements.sort((l, r) => r.floors - l.floors);
				res.grounds = grounds;
				res.basements = basements;

				if (getAuth().id == res.owner) {
					this.auth = true;
				}
				this.data = res;
				this.imageUrl = res.imageId && `/file/${res.imageId}`;
				await this.friendInfo();
			} catch (ex) {
				await this.$alert(`건물 상세조회 에러가 발생했습니다.\r\n(${ex.message})`);
				return this.$router.go(-1);
			} finally {
				hideSpinner();
			}
		},
		async onGetMemo() {
			let params = {
				offset: this.offset,
				limit: this.limit,
				buildingId: this.buildingId,
			};
			try {
				let res = await memo.get(params);
				this.total = res.total;
				if (this.total > 0) {
					res.data.forEach(m => this.memoList.push(m));
					this.offset += this.limit;
				}
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
		},
		async emptyMemo() {
			this.memoList = [];
			this.offset = 0;
		},
		async onAddFriendReq() {
			const emailRegex = /[a-zA-Z0-9_+.-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,4}$/;
			const mobileRegex = /^([0-9]{3})-?([0-9]{3,4})-?([0-9]{4})$/;
			// 이메일 체크
			if (!emailRegex.test(this.friendValue.trim())) {
				// 핸드폰 번호 체크
				if (!mobileRegex.test(this.friendValue.trim())) {
					return await this.$alert(
						`휴대전화 또는 이메일아이디가 아닙니다. \r\n 올바른 형식으로 입력해주세요.`,
						'친구추가 안내',
					);
				} else {
					this.friendKey = 'mobileNo';
					// eslint-disable-next-line no-useless-escape
					this.mobileNo = this.friendValue.replace(/\-/g, '');
				}
			} else {
				this.friendKey = 'loginId';
				this.loginId = this.friendValue;
			}
			try {
				showSpinner();
				await friend.insert(this.buildingId, this.authority, this.friendKey, this.mobileNo, this.loginId);
			} catch (ex) {
				if (ex.status == 2031) {
					// 친구추가 문자 발송
					try {
						await smsSend.friendRegister(this.mobileNo);
					} catch (ex2) {
						return await this.$alert(`${ex2.message}`, '친구추가 안내');
					}
					return await this.$alert(`${this.mobileNo}의 사용자에게 인싸이트노트를 추천하였습니다.`, '친구추가 안내');
				} else if (ex.status == 2201) {
					// 휴대전화 친구 추가
					await this.$alert(`${this.mobileNo}의 사용자에게 건물공유를 요청하였습니다.`, '친구추가 안내');
					try {
						await smsSend.friend(this.mobileNo, this.buildingId);
					} catch (ex2) {
						return await this.$alert(`${ex2.message}`, '친구추가 안내');
					}
				} else if (ex.status == 2202) {
					// 로그인 아이디 친구 추가
					return await this.$alert(`${this.loginId}의 사용자에게 건물공유를 요청하였습니다.`, '친구추가 안내');
				} else {
					// 기타 에러
					return await this.$alert(`${ex.message}`, '친구추가 안내');
				}
			} finally {
				hideSpinner();
			}
			this.addFriendReqClass = 'friendLayer layerPop';
		},
		openFriendReq() {
			this.addFriendReqClass += ' on';
		},
		closeFriendReq() {
			this.addFriendReqClass += 'friendLayer layerPop';
			this.mobileNo = '';
			this.loginId = '';
			this.friendKey = '';
			this.friendValue = '';
		},
		getOwnerships(rooms) {
			return rooms && rooms.filter(it => !!it.tenantName) && new Set(rooms.map(it => it.tenantName));
		},
		onTenant() {
			this.$router.push({ name: 'tenantList', params: { buildingId: this.buildingId } });
		},
		onProject() {
			this.$router.push({ name: 'projectList', params: { buildingId: this.buildingId } });
		},
		onMemoRegist() {
			this.$router.push({
				name: 'memoRegist',
				params: {
					buildingId: this.buildingId,
					reqUrl: 'buildingDetail',
				},
			});
		},
		onMemo(id, flag) {
			this.$router.push({
				name: 'memoDetail',
				params: { id: id, reqUrl: 'buildingDetail', flag: flag, targetDate: this.targetDate },
			});
		},
		enterContent(content) {
			let count = content.match(/\n/g);
			if (count != null && count.length > 4) {
				let tmp = '';
				for (let i = 0; i < 5; i++) {
					tmp += content.split('\n')[i] + '\n';
				}
				content = tmp.substring(0, tmp.length - 1);
			}
			return content;
		},
		onOpenImage(imageId, memoFiles) {
			this.$refs.popUpImage.onOpenImage(imageId, memoFiles);
		},
		// 해당 일주일 구하기
		async getWeek(date) {
			let weekEndDate = this.$moment(date).add(-this.$moment(date).day(), 'days');
			this.weeks = [];
			this.weekDay = [];
			this.weeks.push({ date: weekEndDate.format('YYYY-MM-DD') });
			for (let i = 0; i < 6; i++) {
				this.weeks.push({ date: weekEndDate.add(1, 'days').format('YYYY-MM-DD') });
			}
			this.targetDate = this.$moment(this.weeks[0].date).format('YYYY-MM-DD');
			await this.getWeekSchedule();
		},
		// 일주일 스케일 가져오기
		async getWeekSchedule() {
			let params = {
				targetDate: this.targetDate,
				buildingId: this.buildingId,
			};
			try {
				this.schedule = await schedule.get({ params });
				// 일주일 스케줄 가공
				for (let i = 0; i < 7; i++) {
					let schedule = [];
					if (!this.schedule.data) break;
					for (let j = 0; j < this.schedule.data.length; j++) {
						if (this.$moment(this.schedule.data[j].scheduleDate).isSame(this.$moment(this.weeks[i].date))) {
							schedule.push(this.schedule.data[j]);
						}
						this.weeks[i].schedule = schedule;
					}
				}
				await this.getProjectSchedule();
			} catch (ex) {
				return await this.$alert(ex.message);
			}
		},
		// 이전주 고르기
		async prevWeek() {
			let date = this.$moment(this.weeks[0].date).add(-7, 'days');
			this.targetDate = this.$moment(date)
				.add(-7, 'days')
				.format('YYYY-MM-DD');
			await this.getWeek(date);
			let index = 0;
			this.weeks.forEach(w => {
				if (this.$moment(w.date).isSame(this.$moment().format('YYYY-MM-DD'))) {
					index = this.$moment(w.date).day();
				}
			});
			await this.selectDate(index);
		},
		// 다음주 고르기
		async nextWeek() {
			let date = this.$moment(this.weeks[0].date).add(7, 'days');
			this.targetDate = this.$moment(date)
				.add(7, 'days')
				.format('YYYY-MM-DD');
			await this.getWeek(date);
			let index = 0;
			this.weeks.forEach(w => {
				if (this.$moment(w.date).isSame(this.$moment().format('YYYY-MM-DD'))) {
					index = this.$moment(w.date).day();
				}
			});
			await this.selectDate(index);
		},
		// 날짜 선택하기
		async selectDate(index) {
			this.weekClass = [];
			this.weekClass[index] = 'curr';
			this.targetDate = this.weeks[index].date;
			this.titleDate = this.$moment(this.weeks[index].date).format('YYYY년 M월');
			await this.onGetSchedule(index);
			await this.onGetProject(index);
		},
		// 하루 스케줄 리스트 가져오기
		async onGetSchedule(index) {
			this.scheduleList = this.weeks[index].schedule;
			let scheduleIds = [];
			this.scheduleMemoList = [];
			if (this.scheduleList && this.scheduleList.length > 0) {
				this.scheduleList.forEach(schedule => scheduleIds.push(schedule.id));
				let params = {
					scheduleIds: scheduleIds.join(','),
				};
				try {
					let res = await memo.getScheduleMemo(params);
					res.data.forEach(r => {
						this.scheduleList.forEach(sl => {
							if (r.scheduleId === sl.id) {
								r.schedulename = sl.name;
							}
						});
					});
					this.scheduleMemoList = res.data;
				} catch (ex) {
					return await this.$alert(ex.message);
				}
			}
		},
		getDayOfWeekString(index) {
			let dayOfWeekString = '일';
			switch (index) {
				case 0:
					dayOfWeekString = '일';
					break;
				case 1:
					dayOfWeekString = '월';
					break;
				case 2:
					dayOfWeekString = '화';
					break;
				case 3:
					dayOfWeekString = '수';
					break;
				case 4:
					dayOfWeekString = '목';
					break;
				case 5:
					dayOfWeekString = '금';
					break;
				case 6:
					dayOfWeekString = '토';
					break;
			}
			return dayOfWeekString;
		},
		// 일주일 프로젝트 가져오기
		async getProjectSchedule() {
			let params = {
				buildingId: this.buildingId,
				targetDate: this.targetDate,
			};
			try {
				let res = await project.getSchedule(params);
				for (let i = 0; i < 7; i++) {
					let project = [];
					if (!res.data) break;
					for (let j = 0; j < res.data.length; j++) {
						if (
							(this.$moment(res.data[j].beginDate).isSame(this.$moment(this.weeks[i].date)) ||
								this.$moment(res.data[j].beginDate).isBefore(this.$moment(this.weeks[i].date))) &&
							(this.$moment(res.data[j].endDate).isAfter(this.$moment(this.weeks[i].date)) ||
								this.$moment(res.data[j].endDate).isSame(this.$moment(this.weeks[i].date)))
						) {
							project.push(res.data[j]);
						}
						this.weeks[i].project = project;
					}
				}
			} catch (ex) {
				return await this.$alert(ex.message);
			}
		},
		// 하루 프로젝트 가져오기
		async onGetProject(index) {
			this.projectList = this.weeks[index].project;
		},
		onProjectMemoList(projectId) {
			this.$router.push({ name: 'projectMemoList', params: { buildingId: this.buildingId, id: projectId } });
		},
		async friendInfo() {
			let res = await building.getOne(this.buildingId);
			if (getAuth().id != res.owner) {
				let params = {
					buildingId: this.buildingId,
					userId: res.owner,
					friendId: getAuth().id,
					state: 'F',
				};
				let res2 = await friend.getOne(params);
				if (res2.authority == 'A') this.friendAuth = true;
			} else {
				this.friendAuth = true;
			}
		},
		setIntervalStr(intervalType) {
			return setIntervalStr(intervalType);
		},
		setDateFormat(schedule) {
			let date = schedule.beginDate;
			switch (schedule.intervalType) {
				case 'Y':
					date = this.$moment(date).format('M월 D일');
					break;
				case 'M':
					date = this.$moment(date).format('D일');
					break;
				case 'W':
					switch (this.$moment(date).day()) {
						case 0:
							date = '일요일';
							break;
						case 1:
							date = '월요일';
							break;
						case 2:
							date = '화요일';
							break;
						case 3:
							date = '수요일';
							break;
						case 4:
							date = '목요일';
							break;
						case 5:
							date = '금요일';
							break;
						case 6:
							date = '토요일';
							break;
					}
					break;
				case 'D':
					date = this.$moment(date).format('YYYY월 M월 D일');
					break;
			}
			return date;
		},
	},
};
</script>

<style scoped>
.locationCount {
	padding-left: 5px;
	background: none;
}
.buildWrap .buildArea .build,
.buildWrap .buildArea .build ul {
	background: none;
}
</style>
